<template  >
    <div class="d-flex flex-column ml-5 mt-3 mb-5 w-20" style="min-width: 290px;">
        <h1 class="title w-100">{{title}}</h1>
        <form @submit.prevent="checkForm" method="post"   class="mt-3 d-flex flex-column w-100">
            
            <div class="d-flex flex-column w-100">
                <label for="mail" class="font-weight-bold text-primary">Correo</label>
                <input type="email" name="mail" v-model="user.email" class="mt-5 pl-2 w-100 text-primary" required maxlength="100" />
            </div>
            <div class="d-flex flex-column w-100 mt-5">
                <label for="password" class="font-weight-bold text-primary">Contrase&ntilde;a</label>
                <input type="password" name="password" v-model="user.password" minlength="6" class="mt-5 pl-2 w-100 text-primary" required maxlength="30" />
            </div>
            <div class="d-flex flex-row align-items-center mt-5">
                <label for="remind_me" class="text-secondary font-weight-bold cursor-pointer">Recordarme</label>
                <input type="checkbox" id="remind_me" name="remind_me" class="ml-3 cursor-pointer">
            </div>

            <p id="error" v-if="errors.length" class="card p-2">
                <span class="font-medium font-weight-bold ">Mensajes del error:</span>
                <ul>
                    <li class="m-2" v-for="error in errors" :key="error.id">{{ error }}</li>
                </ul>
            </p>
            <div v-if="success" class="pt-5 pb-5 text-center">Conectando...</div>
            <button type="submit" class="btn text-uppercase text-center mt-10 w-100 cursor-pointer">Entrar</button>
            <router-link to="/update-password" class="mt-5 text-center w-100 text-primary text-uppercase">
                ¿Olvidaste la contrase&ntilde;a?
            </router-link>
        </form>
    </div>
</template>

<script>
import _service from "@/services/authService";
import _securityToken from "@/core/securityToken";
export default {
    name: "Form",
    props: {
        title: String,
    },
    components: {},
    data: function () {
        return {
            success: false,
            loading:false,
            email: "",
            password: "",
            errors: [],
            token: null,
            user: {
                email: null,
                password: null,
            },
        };
    },
    methods: {
        checkForm: function () {
            this.errors = [];
            this.success = false;
            if (!this.user.email) {
                this.errors.push("El campo correo es requerido");
            }
            if (!this.user.password) {
                this.errors.push("El campo contraseña es requerido");
            }
            if (this.user.email && this.user.password) {
                return this.submit();
            }
        },
        async submit() {
            this.$emit("loading", true);
            _service
                .signIn(this.user)
                .then((data) => {
                    if (data.data.successful) {
                        _securityToken.registerToken(data.data);
                        this.$store.dispatch("setUserRole", data.data.role);
                        if (data.data.role == "Administrador") {
                            this.$router.push({ name: "Users" });
                        } else {
                            this.$router.push({ name: "Orders" });
                        }
                    } else {
                        this.errors.push(data.data.errors);
                    }
                           this.$emit("loading", false);
                })
                .catch(() => {
                    this.errors.push(
                        "El correo o la contraseña no son válidos"
                    );
   this.$emit("loading", false);

                });
        },
    },
};
</script>


<style scoped>
</style>