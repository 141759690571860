<template>
    <div class="w-100" v-loading="isLoading">
        <Header />
        <div class="w-100 d-flex flex-row flex-wrap justify-between">
            <Form @loading="loading" title="Iniciar sesión" />
            <img
                src="@/assets/illustration.svg"
                style="max-width: 800px"
                alt=""
                class="w-100 mr-5"
            />
        </div>
        <Footer />
    </div>
</template>

<script>
import Form from "./components/form.vue";
import Header from "@/components/include/Header.vue";
import Footer from "@/components/include/Footer.vue";

export default {
    name: "Login",
    props: {
        title: String,
    },
    components: {
        Header,
        Form,
        Footer,
    },
    data() {
        return {
            isLoading: false,
        };
    },
    methods: {
        loading(value) {
            this.isLoading = value;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
