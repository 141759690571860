<template>
    <div class="w-100">
        <div class="ml-5 mt-5">
            <span class="copyright text-black font-weight-bold font-medium"
                >&copy; 2021 Pormoto.com</span
            >
        </div>
        <div class="mt-4 bg-primary w-100" style="height: 31px"></div>
    </div>
</template>

<style scoped>
.copyright {
    color: var(--unnamed-color-000000);
    text-align: left;
    font-family: Nunito;
    font-size: 14px;
    letter-spacing: 0px;
    opacity: 1;
}
</style>
