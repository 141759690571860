import { authApi } from "../core/api";
export default {
    signIn(params) {
        return authApi.post("/Authentication/Signin", params);
    },
    logout() {
        localStorage.removeItem("pormoto_token");
        localStorage.removeItem("pormoto_userId");
    },
};
