<template>
    <el-header class="w-100 d-flex flex-row justify-between mt-3">
        <router-link to="/" class="ml-4">
            <img src="@/assets/logo.svg" height="75" alt="" class="" />
        </router-link>
    </el-header>
</template>

<script>
import "@/assets/main.css";

export default {
    name: "Header",
    components: {},
};
</script>
